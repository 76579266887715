import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RelationBinome} from '../../model/user/relation-binome.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class RelationBinomeWebservice extends AbstractWebservice {
  entity = `relationBinome`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getRelationBinomeById(id: number) {
    return this.get(`${this.root}/${id}`);
  }

  updateRelationBinome(relationBinome: RelationBinome) {
    return this.put(`${this.root}`, relationBinome);
  }

  postRelationBinome(relationBinome: RelationBinome) {
    return this.post(`${this.root}`, relationBinome);
  }

  deleteRelationBinome(id: number) {
    return this.delete(`${this.root}/${id}`);
  }
}
