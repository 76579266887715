import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Session} from 'src/app/model/event/session.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class SessionWebservice extends AbstractWebservice {
  entity = `sessions`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getSessionById(idSession: number) {
    return this.get(`${this.root}/${idSession}`);
  }

  getAllSessions() {
    return this.get(`${this.root}`);
  }

  getSessionTreeData(isActive: boolean) {
    return this.get(`${this.root}/tree/${isActive}`);
  }

  postAddSession(session: Session) {
    return this.post(`${this.root}/`, session);
  }

  putUpdateSession(session: Session) {
    return this.put(`${this.root}/update`, session);
  }

  deleteSession(idSession: number) {
    return this.delete(`${this.root}/${idSession}`);
  }
}
