import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {FilterYoungJob} from '../../model/filter/filter-young-job.model';
import {FilterJobZipCodeDTO} from '../../model/dto/filter/filter-job-zipcode.dto';
import {MassModificationStatusDto} from '../../model/dto/mass-modification-status.dto';
import {MassModificationRiDto} from '../../model/dto/mass-modification-ri.dto';
import {FilterUser} from '../../model/filter/filter-relation-binome/filter-user.model';
import {Observable} from 'rxjs';
import {FilterRelationBinomeYoungDTO} from '../../model/filter/filter-relation-binome/filter-relation-binome-young.dto';
import {Interview} from '../../model/user/interview.model';
import {WorkExperience} from '../../model/user/work-experience.model';
import {Monitoring} from '../../model/monitoring/monitoring.model';
import {FilterYoungSport} from '../../model/filter/filter-young-sport.model';
import {SimpleTextFilter} from '../../model/filter/simple-text-filter.model';
import {FilterYoungLdlv} from '../../model/filter/filter-young-ldlv.model';
import {YoungAnswer} from '../../model/survey/young-answer.model';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {SimpleYoungDTO} from '../../model/dto/simple-young.dto';
import {isNotNullOrUndefined} from '../../utils/utils.static';
import {Break} from "../../model/user/Break";
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class YoungWebservice extends AbstractWebservice {
  entity = `youngs`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getYoung(youngId: number): Observable<SimpleYoungDTO> {
    return this.get<SimpleYoungDTO>(`${this.root}/${youngId}`);
  }

  getEventsFromYoung(youngId: number, year: number) {
    return this.get(`${this.root}/${youngId}/events?year=${year}`);
  }

  getEventFromYoung(youngId: number, eventId: number) {
    return this.get(`${this.root}/${youngId}/event/${eventId}`);
  }

  getInfosFromYoung(id: number) {
    return this.get(`${this.root}/${id}/infos`);
  }

  getAllRegisteredYoungsByYear(searchYear: number) {
    return this.get(`${this.root}/registered/year/${searchYear}`);
  }

  getAllYoungsNameByYear(searchYear: number, value: SimpleTextFilter): Observable<string[]> {
    return this.post<string[]>(`${this.root}/firstname/${searchYear}`, value);
  }

  getAllYoungsLastNameByYear(searchYear: number, value: SimpleTextFilter): Observable<string[]> {
    return this.post<string[]>(`${this.root}/lastname/${searchYear}`, value);
  }

  getAllYoungsByName(value: FilterUser): Observable<FilterRelationBinomeYoungDTO[]> {
    return this.post<FilterRelationBinomeYoungDTO[]>(`${this.root}/youngName`, value);
  }

  getAllYoungsZipCodeByYear(searchYear: number, value: FilterJobZipCodeDTO): Observable<string[]> {
    return this.post<string[]>(`${this.root}/zipcode/${searchYear}`, value);
  }

  massModificationStatus(searchYear: number, value: MassModificationStatusDto) {
    return this.post(`${this.root}/massModificationStatus/${searchYear}`, value);
  }

  massModificationRi(value: MassModificationRiDto) {
    return this.post(`${this.root}/massModificationRi`, value);
  }

  getAllRegisteredYoungsJob(filterYoungJob: FilterYoungJob, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('jdlv')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filterYoungJob);
  }

  getAllRegisteredYoungsSport(filterYoungSport: FilterYoungSport, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('sdlv')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filterYoungSport);
  }

  getAllRegisteredYoungsLdlv(filterYoungLdlv: FilterYoungLdlv, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('ldlv')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filterYoungLdlv);
  }

  getUnregisteredYoungs(idEvent: number, filter, pageRequest: PageRequest) {
    let idPlace;
    let ageMin;
    let ageMax;
    let eventTypeNames = [];
    let idUser;
    let lIdUserRi;
    let lIdParcours;
    let lIdAccompaniments;
    let lIdNeeds;
    let lIdPrograms;
    let lSexeEnum;
    let lIdPlaces;
    if (filter) {
      idPlace = filter.idPlace;
      ageMin = filter.ageMin;
      ageMax = filter.ageMax;
      eventTypeNames = filter.choixEventTypeName;
      idUser = filter.idUser;
      lIdUserRi = filter.lIdUserRi;
      lIdAccompaniments = filter.lIdAccompaniments;
      lIdParcours = filter.lIdParcours;
      lIdNeeds = filter.lIdNeeds;
      lIdPrograms = filter.choixProgram;
      lSexeEnum = filter.choixSexes;
      lIdPlaces = filter.choixPlace;
    }

    const filtre = {
      idPlace,
      idEvent,
      eventTypeNames,
      idUser,
      lIdUserRi,
      lIdAccompaniments,
      lIdNeeds,
      lIdParcours,
      lIdPrograms,
      lSexeEnum,
      lIdPlaces
    };

    let url = new UrlBuilder(this.root)
      .addPathParam('unregistered')
      .addPagination(pageRequest);

    if (isNotNullOrUndefined(ageMin) && isNotNullOrUndefined(ageMax)) {
      url = url
        .addQueryParam('ageMin', ageMin)
        .addQueryParam('ageMax', ageMax);
    }

    return this.post(url.build(), filtre);
  }

  checkDuplicateYoung(youngId: number) {
    return this.get(`${this.root}/checkDuplicate/${youngId}`);
  }

  addOrUpdateInterview(interview: Interview) {
    return this.post(`${this.root}/addOrUpdateInterview`, interview);
  }

  addOrUpdateBreak(pbreak: Break) {
    return this.post(`${this.root}/addOrUpdateBreak`, pbreak);
  }

  addOrUpdateWorkExperience(workExperience: WorkExperience) {
    return this.post(`${this.root}/addOrUpdateWorkExperience`, workExperience);
  }

  addOrUpdateMonitoring(monitoring: Monitoring) {
    return this.post(`${this.root}/addOrUpdateMonitoring`, monitoring);
  }

  getWorkExperience(idWorkExperience: number) {
    return this.get(`${this.root}/workExperience/${idWorkExperience}`);
  }

  getWorkExperiences(youngId: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam(youngId)
      .addPathParam('workExperiences')
      .addPagination(pageRequest)
      .build();

    return this.get(url);
  }

  getInterview(idInterview: number) {
    return this.get(`${this.root}/interview/${idInterview}`);
  }

  getInterviews(youngId: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam(youngId)
      .addPathParam('interviews')
      .addPagination(pageRequest)
      .build();

    return this.get(url);
  }

  getBreak(idBreak: number) {
    return this.get(`${this.root}/break/${idBreak}`);
  }

  getBreaks(youngId: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam(youngId)
      .addPathParam('breaks')
      .addPagination(pageRequest)
      .build();

    return this.get(url);
  }

  getMonitoring(idBreak: number) {
    return this.get(`${this.root}/monitoring/${idBreak}`);
  }

  getMonitorings(youngId: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam(youngId)
      .addPathParam('monitorings')
      .addPagination(pageRequest)
      .build();

    return this.get(url);
  }

  getLastStatus(idYoung: number) {
    return this.get(`${this.root}/lastStatus/${idYoung}`);
  }

  getAllWorkExperience(idYoung: number) {
    return this.get(`${this.root}/${idYoung}/workExperiences/all`);
  }

  getJobSummary(idYoung: number) {
    return this.get(`${this.root}/${idYoung}/job-summary`);
  }

  updateFseInfo(idYoung, updateFseDto) {
    return this.put(`${this.root}/${idYoung}/update-fse`, updateFseDto);
  }

  updateSurvey(youngAnswers: YoungAnswer[]) {
    return this.put(`${this.root}/update-survey`, youngAnswers);
  }

  deleteRelationInterview(id: number) {
    return this.delete(`${this.root}/deleteEntretien/${id}`);
  }

  deleteRelationBreak(id: number) {
    return this.delete(`${this.root}/deleteBreak/${id}`);
  }

  deleteWorkExperience(id: number) {
    return this.delete(`${this.root}/deleteWorkExperiences/${id}`);
  }

  getAllJobbeurNeed(idUser: number) {
    return this.get(`${this.root}/${idUser}/jobbeurNeeds`);
  }

  getLatestParcours(idUser: number) {
    return this.get(`${this.root}/latest-parcours/${idUser}`);
  }
}
