import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterIntervenant} from '../../model/filter/filter-intervenant.model';
import {SpeakerLightDTO} from '../../model/dto/speaker-light.dto';
import {SpeakerDTO} from '../../model/dto/speaker.dto';
import {FilterUser} from '../../model/filter/filter-relation-binome/filter-user.model';
import {FilterRelationBinomeIntervenantDTO} from '../../model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {FilterYoungJob} from '../../model/filter/filter-young-job.model';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class SpeakerService extends AbstractWebservice {
  entity = `speaker`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  createSpeaker(intervenant: SpeakerDTO): Observable<number> {
    return this.post<number>(`${this.root}`, intervenant);
  }

  editSpeaker(intervenant: SpeakerDTO): Observable<number> {
    return this.put<number>(`${this.root}`, intervenant);
  }

  getIntervenant(filter: FilterIntervenant, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParams('list')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filter);
  }

  checkExisting(firstname, lastname) {
    return this.get(`${this.root}/${lastname}/${firstname}`);
  }

  getIntervenantBinome(filter: FilterYoungJob, idIntervenant: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParams('binome', idIntervenant)
      .addPagination(pageRequest)
      .build();

    return this.post(url, filter);
  }

  getYoungBinome(filter: FilterYoungJob, idYoung: number, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParams('binomeYoung', idYoung)
      .addPagination(pageRequest)
      .build();

    return this.post(url, filter);
  }

  getAllSpeakersByName(value: FilterUser): Observable<FilterRelationBinomeIntervenantDTO[]> {
    return this.post<FilterRelationBinomeIntervenantDTO[]>(`${this.root}/speakerName`, value);
  }

  getSpeaker(idSpeaker: number): Observable<SpeakerLightDTO> {
    return this.get<SpeakerLightDTO>(`${this.root}/${idSpeaker}`);
  }
}
