import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getMobileToken} from "../../utils/utils.static";
import {environment} from "../../../environments/environment";

type HttpOptions = {headers?: HttpHeaders | {[p: string]: string | string[]}, context?: HttpContext, observe?: "body" | "events" | "response", params?: HttpParams | {}, reportProgress?: boolean, responseType?: "json" | "blob", withCredentials?: boolean, transferCache?: boolean | {}};

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractWebservice {
  protected readonly baseUrl = `${environment.envApiUrl}`;
  protected entity: string = '';

  protected http: HttpClient;
  protected httpMobile: HttpClient;

  protected constructor(protected readonly defaultHttpClient: HttpClient,
                        protected readonly httpBackend: HttpBackend) {
    // HttpClient initialisé normalement
    this.http = defaultHttpClient;

    // HttpClient qui bypass l'intercepteur en utilisant le HttpBackend
    this.httpMobile = new HttpClient(httpBackend);
  }

  /**
   * Retourne l'URL de base pour les requêtes HTTP
   */
  get root(): string {
    return `${this.baseUrl}/${this.entity}`;
  }

  /**
   * Gère les options pour les requêtes HTTP, notamment l'authorization si le token mobile est présent
   */
  protected handleOptions(options: HttpOptions = {}): unknown {
    const token = getMobileToken();
    if (token) {
      return {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`
        }),
        ...options
      };
    } else {
      return options;
    }
  }

  /**
   * Méthode GET
   */
  protected get<T>(url: string, defaultOptions: HttpOptions = {}): Observable<T> {
    const options = this.handleOptions(defaultOptions);
    const token = getMobileToken();

    if (token) {
      // Si token mobile existe, appel avec httpMobile
      return this.httpMobile.get<T>(url, options);
    } else {
      // Sinon, appel avec le httpClient normal
      return this.http.get<T>(url, options);
    }
  }

  /**
   * Méthode POST
   */
  protected post<T>(url: string, body: unknown, defaultOptions: unknown = {}): Observable<T> {
    const options = this.handleOptions(defaultOptions);
    const token = getMobileToken();

    if (token) {
      // Si token mobile existe, appel avec httpMobile
      return this.httpMobile.post<T>(url, body, options);
    } else {
      // Sinon, appel avec le httpClient normal
      return this.http.post<T>(url, body, options);
    }
  }

  /**
   * Méthode PUT
   */
  protected put<T>(url: string, body: unknown, defaultOptions: HttpOptions = {}): Observable<T> {
    const options = this.handleOptions(defaultOptions);
    const token = getMobileToken();

    if (token) {
      // Si token mobile existe, appel avec httpMobile
      return this.httpMobile.put<T>(url, body, options);
    } else {
      // Sinon, appel avec le httpClient normal
      return this.http.put<T>(url, body, options);
    }
  }

  /**
   * Méthode DELETE
   */
  protected delete<T>(url: string, defaultOptions: HttpOptions = {}): Observable<T> {
    const options = this.handleOptions(defaultOptions);
    const token = getMobileToken();

    if (token) {
      // Si token mobile existe, appel avec httpMobile
      return this.httpMobile.delete<T>(url, options);
    } else {
      // Sinon, appel avec le httpClient normal
      return this.http.delete<T>(url, options);
    }
  }
}
