export class PageRequest {
  constructor(public pageNumber: number, public pageSize: number) {}
}

export class UrlBuilder {
  private readonly baseUrl: string;
  private readonly pathParams: string[] = [];
  private queryParams: { [key: string]: string | number | boolean } = {};

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * Ajoute un path paramètre à l'URL
   * Exemple: /users/123
   */
  addPathParam(param: string | number | boolean): UrlBuilder {
    this.pathParams.push(encodeURIComponent(String(param)));
    return this;  // Pour permettre le chaînage
  }

  /**
   * Ajoute plusieurs path params à l'URL
   * Exemple: /users/123/posts/456
   */
  addPathParams(...params: (string | number | boolean)[]): UrlBuilder {
    params.forEach(param => this.addPathParam(param));
    return this;  // Pour permettre le chaînage
  }

  /**
   * Ajoute un objet de pagination en tant que query params
   * @param pageRequest Objet contenant pageNumber et pageSize
   */
  addPagination(pageRequest: PageRequest): UrlBuilder {
    this.addQueryParam('pageNumber', pageRequest.pageNumber);
    this.addQueryParam('pageSize', pageRequest.pageSize);
    return this;  // Pour permettre le chaînage
  }

  /**
   * Ajoute un ou plusieurs query parameters à l'URL
   * Exemple: ?filter=active&page=2
   */
  addQueryParam(key: string, value: string | number | boolean): UrlBuilder {
    this.queryParams[encodeURIComponent(key)] = encodeURIComponent(String(value));
    return this;  // Pour permettre le chaînage
  }

  /**
   * Retourne l'URL finale avec les pathParams et queryParams concaténés
   */
  build(): string {
    let url = this.baseUrl;

    // Ajouter les pathParams
    if (this.pathParams.length > 0) {
      url += '/' + this.pathParams.join('/');
    }

    // Ajouter les queryParams
    const queryParamStrings = Object.keys(this.queryParams).map(key => {
      return `${key}=${this.queryParams[key]}`;
    });

    if (queryParamStrings.length > 0) {
      url += '?' + queryParamStrings.join('&');
    }

    return url;
  }
}
