import {Injectable} from '@angular/core';
import {User} from '../../model/user/user.model';
import {Observable} from 'rxjs';
import {UserCheckingDTO} from '../../model/dto/user-checking.dto';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {UserProfileDTO} from 'src/app/model/dto/user-profile.dto';
import {UserFamilyDTO} from 'src/app/model/dto/user-family.dto';
import {RegisterErrorDTO} from 'src/app/model/dto/register-error.dto';
import {FamilySettingDTO} from '../../model/dto/family-setting.dto';
import {IntervenantTypeEnum} from '../../model/enums/intervenant-type.enum';
import {FilterUser} from '../../model/filter/filter-relation-binome/filter-user.model';
import {FusionUserDTO} from '../../model/dto/fusion-user.dto';
import {UserSearchRequest} from '../../model/request/user-search-request.model';
import {Paged} from '../../model/response/paged.model';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {UserSearch} from '../../model/user/user-search.model';
import {FamilySettingRequest} from '../../model/request/family-setting-request.model';
import {FilterRelationBinomeIntervenantDTO} from '../../model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {UserIntervenantDto} from 'src/app/model/dto/user-intervenant.dto';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class UserWebservice extends AbstractWebservice {
  entity = `users`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getUserById(idUser: number) {
    return this.get(`${this.root}/${idUser}`);
  }

  refreshCurrentUser() {
    return this.get(`${this.root}`);
  }

  getFamilies(
    searchRequest: FamilySettingRequest,
    pageRequest: PageRequest
  ): Observable<Paged<FamilySettingDTO>> {
    const url = new UrlBuilder(this.root)
      .addPathParams('families', 'all')
      .addPagination(pageRequest)
      .build();

    return this.post<Paged<FamilySettingDTO>>(url, searchRequest);
  }

  getFamilyForUser(id: number): Observable<UserFamilyDTO[]> {
    return this.get<UserFamilyDTO[]>(`${this.root}/families/${id}`);
  }

  postRegisterUser(dataUser: User) {
    // Inscription jeune: inscrit un nouveau jeune
    return this.post(`${this.root}/register/complete`, dataUser);
  }

  postSmsValidation(user: User, isJob: boolean = false) {
    // Sms pour les jeunes web qui sont validés
    return this.post(`${this.root}/validation/sms?isJob=${isJob}`, user);
  }

  // Check si un user existe déjà grâce à son nom/prénom/date de naissance, renvoie un jeune ou 404
  postCheckUserExist(dataUser: UserCheckingDTO) {
    return this.post(`${this.root}/checking`, dataUser);
  }

  // Check si un user existe déjà grâce à son id, renvoie un jeune ou 404
  getCheckUserExistById(idUser: number) {
    return this.get(`${this.root}/checking/${idUser}`);
  }

  getOldPlaceByUserId(idUser: number) {
    return this.get(`${this.root}/lastplace/${idUser}`);
  }

  postUserFamilySetting(family: UserFamilyDTO): Observable<RegisterErrorDTO> {
    return this.post<RegisterErrorDTO>(`${this.root}/family/add`, family);
  }

  postDeleteUserFamilySetting(family: UserFamilyDTO): Observable<RegisterErrorDTO> {
    return this.post<RegisterErrorDTO>(`${this.root}/family/remove`, family);
  }

  getAllUserProfils(justUserSDLV: boolean = undefined) {
    const url = new UrlBuilder(this.root)
      .addPathParams('profiles')
      .addQueryParam('userSDLV', justUserSDLV ? 'true' : 'false')
      .build();

    return this.get(url);
  }

  getUserSDLVAutocomplete(name?: string) {
    const url = new UrlBuilder(this.root)
      .addPathParams('user-sdlv-autocomplete')
      .addQueryParam('name', name ?? '')
      .build();
    return this.get(url);
  }

  getProfileByIdUser(idUser: number) {
    return this.get(`${this.root}/profiles/${idUser}`);
  }

  getTutorByYear(year: number) {
    return this.get(`${this.root}/tutor/year/${year}`);
  }

  postCreateProfile(profile: UserProfileDTO) {
    return this.post(`${this.root}/profiles`, profile);
  }

  postCheckMailExist(email: string, id: number = null) {
    return this.post(`${this.root}/check/mail?userid=${id ? id : 0}`, {content: email});
  }

  postCheckPhoneExist(phone: string, id: number = null) {
    return this.post(`${this.root}/check/phone?userid=${id ? id : 0}`, {content: phone});
  }

  putUpdateProfile(listOfProfiles: UserProfileDTO[]) {
    return this.put(`${this.root}/profiles`, listOfProfiles);
  }

  deleteUser(idUser: number, isTotalSuppression: boolean) {
    return this.delete(`${this.root}/${idUser}/${isTotalSuppression}`);
  }

  fusion(fusionUserDto: FusionUserDTO) {
    return this.post(`${this.root}/fusion`, fusionUserDto);
  }

  findByPhone(phone: string): Observable<User[]> {
    return this.post<User[]>(`${this.root}/phone`, {content: phone});
  }

  findByMail(mail: string): Observable<User[]> {
    return this.post<User[]>(`${this.root}/mail`, {content: mail});
  }

  deleteTutor(listOfIdUser: number[]) {
    return this.post(`${this.root}/tutor/delete`, listOfIdUser);
  }

  isValid(idUser: number) {
    return this.get(`${this.root}/isValid/${idUser}`);
  }

  getAllRiFromPlace(idPlace: number) {
    return this.get(`${this.root}/ri/${idPlace}`);
  }

  getAllRi() {
    return this.get(`${this.root}/ri`);
  }

  getAllVisitors() {
    return this.get(`${this.root}/visitor/all`);
  }

  getAllVisitorsActif() {
    return this.get(`${this.root}/visitor/actif`);
  }

  getAllIntervenant(
    intervenantType: IntervenantTypeEnum,
    filter: FilterUser
  ): Observable<Paged<User>> {
    return this.post<Paged<User>>(`${this.root}/intervenant/${intervenantType}`, filter);
  }

  getIntervenantEventAutocomplete(filter: FilterUser): Observable<Paged<UserIntervenantDto>> {
    return this.post<Paged<UserIntervenantDto>>(`${this.root}/intervenant-event`, filter);
  }

  getYoungByRi(idEvent: number, idRi: number): Observable<number> {
    return this.get(`${this.root}/youngs/${idEvent}/${idRi}`)
      .pipe(map((value) => value as number));
  }

  getAllUserSDLVByName(valueToSend: FilterUser): Observable<FilterRelationBinomeIntervenantDTO[]> {
    return this.post<FilterRelationBinomeIntervenantDTO[]>(
      `${this.root}/sdlvName/`,
      valueToSend
    );
  }

  searchInAllUsers(
    request: UserSearchRequest,
    pageRequest: PageRequest
  ): Observable<Paged<UserSearch>> {
    const url = new UrlBuilder(this.root)
      .addPathParam('search')
      .addPagination(pageRequest)
      .build();

    return this.post<Paged<UserSearch>>(url, request);
  }

  getUserForEdition(id: number): Observable<UserSearch> {
    return this.get<UserSearch>(`${this.root}/edit/${id}`);
  }

  checkPhoneForEdition(phone: string): Observable<UserSearch[]> {
    return this.post<UserSearch[]>(`${this.root}/edit/check-phone`, {
      content: phone
    });
  }

  checkEmailForEdition(email: string): Observable<UserSearch[]> {
    return this.post<UserSearch[]>(`${this.root}/edit/check-email`, {
      content: email
    });
  }

  editUser(id: number, user: UserSearch): Observable<UserSearch> {
    return this.put<UserSearch>(`${this.root}/edit/${id}`, user);
  }
}
