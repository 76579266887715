export enum ReferentielEnum {
  DOMAINE_METIER = 0,
  FINANCING_MECHANISM = 1,
  INTEREST_FIELD = 2,
  INTERVIEW_THEME = 3,
  NEED = 4,
  PROJECT = 5,
  SCHOOL_LEVEL = 6,
  THEMATIQUE = 7,
  TRAVEL = 8,
  TYPE_CONTACT = 9,
  ACCOMPANIMENT_CATEGORY = 10,
  ACTIVITY_TYPE = 11,
  CONTRACT_TYPE = 12,
  CONTRIBUTION_CONTRACT = 13,
  DISPONIBILITE = 14,
  DOCUMENT = 15,
  ENTREPRISE_NEED = 16,
  EVENT_THEME = 17,
  EXPERIENCE_CATEGORY = 18,
  EXPERIENCE_END_MOTIF = 19,
  FORMATION_TYPE = 20,
  INTERVIEW_PLACE = 21,
  MEANS_TRANSPORT = 22,
  MOBILITY = 23,
  ORIGIN = 24,
  PARCOURS = 25,
  PILLAR = 26,
  PROFIL_JEUNE = 27,
  SOCIAL_MEDIA = 28,
  STATUT_CHANGE_MOTIF = 29,
  STATUT_SITUATION_COMPLEMENT = 30,
  NEED_STATUS = 31,
  MONITORING_STATUS = 32,
  SITUATION_AT_DATE = 33,
  TYPE_INTERVENTION = 34
}

export namespace ReferentielEnum {
  export function format(referentiel: number) {
    const referentielEnumMapping = {
      [ReferentielEnum.DOMAINE_METIER]: 'Domaine métier',
      [ReferentielEnum.FINANCING_MECHANISM]: 'Dispositif de financement',
      [ReferentielEnum.INTERVIEW_THEME]: 'Thème des entretiens',
      [ReferentielEnum.INTEREST_FIELD]: "Secteurs d'intérêts",
      [ReferentielEnum.NEED]: 'Besoin des jobbeurs',
      [ReferentielEnum.PROJECT]: 'Projets',
      [ReferentielEnum.SCHOOL_LEVEL]: "Niveaux d'études",
      [ReferentielEnum.THEMATIQUE]: "Thématique d'ensemble",
      [ReferentielEnum.TRAVEL]: 'Séjours',
      [ReferentielEnum.TYPE_CONTACT]: 'Type de contact',
      [ReferentielEnum.ORIGIN]: 'Origine des jeunes',
      [ReferentielEnum.PILLAR]: 'Piliers',
      [ReferentielEnum.STATUT_CHANGE_MOTIF]: 'Statut (Suivi)',
      [ReferentielEnum.CONTRACT_TYPE]: 'Type de contrat',
      [ReferentielEnum.EXPERIENCE_CATEGORY]: "Catégorie d'expérience professionnelle",
      [ReferentielEnum.FORMATION_TYPE]: 'Type de formation',
      [ReferentielEnum.INTERVIEW_PLACE]: 'Lieu des entretiens',
      [ReferentielEnum.MEANS_TRANSPORT]: 'Moyens de transport',
      [ReferentielEnum.MOBILITY]: 'Mobilité',
      [ReferentielEnum.PARCOURS]: 'Parcours',
      [ReferentielEnum.PROFIL_JEUNE]: 'Profil Jeune',
      [ReferentielEnum.SOCIAL_MEDIA]: 'Réseaux sociaux',
      [ReferentielEnum.STATUT_SITUATION_COMPLEMENT]: 'Situation à date - Complément',
      [ReferentielEnum.ACCOMPANIMENT_CATEGORY]: "Catégorie d'accompagnement",
      [ReferentielEnum.ACTIVITY_TYPE]: "Type d'activité",
      [ReferentielEnum.CONTRIBUTION_CONTRACT]: 'Contribution au contrat',
      [ReferentielEnum.DISPONIBILITE]: 'Disponibilité',
      [ReferentielEnum.DOCUMENT]: 'Document',
      [ReferentielEnum.ENTREPRISE_NEED]: 'Besoin des entreprises',
      [ReferentielEnum.EVENT_THEME]: "Thématique d'événement",
      [ReferentielEnum.EXPERIENCE_END_MOTIF]: "Motif de fin d'expérience professionnelle",
      [ReferentielEnum.NEED_STATUS]: 'Statut des besoins pro',
      [ReferentielEnum.MONITORING_STATUS]: 'Statut des suivis',
      [ReferentielEnum.SITUATION_AT_DATE]: 'Situation à date',
      [ReferentielEnum.TYPE_INTERVENTION]: "Type d'intervention"
    };

    return referentielEnumMapping[referentiel];
  }
}
