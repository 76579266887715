import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CenterDTO} from '../../model/dto/center.dto';
import {PlaceDTO} from '../../model/dto/place.dto';
import {Place} from 'src/app/model/event/place/place.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class PlaceWebservice extends AbstractWebservice {
  entity = `places`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getPlaceById(idPlace: number) {
    return this.get(`${this.root}/${idPlace}`);
  }

  getYoungByCenter(idEvent: number, idPlace: number): Observable<number> {
    return this
      .get(`${this.root}/youngs/${idEvent}/${idPlace}`)
      .pipe(map((value) => value as number));
  }

  postAddPlace(place: Place) {
    return this.post(`${this.root}`, place);
  }

  deletePlace(idPlace: number) {
    return this.delete(`${this.root}/${idPlace}`);
  }

  putUpdatePlace(place: Place) {
    return this.put(`${this.root}/update`, place);
  }

  getTerritories(): Observable<Place[]> {
    return this.get<Place[]>(`${this.root}/territories`);
  }
}
